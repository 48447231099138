import React, { useEffect } from "react";

import ItemPosto from "../ItemPosto";
import { Box, CircularProgress, Typography } from "@mui/material";
import ImgNoPermissionGps from "images/no_permisison_location.webp";
import { useSelector } from "react-redux";

const PostosList = ({ list, location, filter, destinyDetails, filterByAleloMobilidade, filterByGoodCard }) => {
  const { postoListLoading } = useSelector((state) => state.posto.list);

  useEffect(() => {
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (e) {}
  }, []);

  const listSorted = list
  .filter(
    (postoItem) =>
      postoItem.rota.distance.value <= Number(filter.distancia) * 1000
  )
  .filter(
    postoItem => (
      (filterByAleloMobilidade || filterByGoodCard)
      ? ((postoItem.aleloMobilidade === true && filterByAleloMobilidade) ? true
      : (postoItem.goodcard === true && filterByGoodCard))
      : true
    )
  )
  .sort(function (a, b) {
    return b[filter.combustivel] - a[filter.combustivel];
  })
  .sort(function (a, b) {
    if (b[filter.combustivel] > 0 && a[filter.combustivel] > 0) {
      return a[filter.combustivel] - b[filter.combustivel];
    } else {
      return 0;
    }
  })

  return (
    <Box>
      <Box
        sx={{
          display:
            list.filter(
              (postoItem) =>
                postoItem.rota.distance.value <= Number(filter.distancia) * 1000 && ((filterByAleloMobilidade || filterByGoodCard)
              ? ((postoItem.aleloMobilidade === true && filterByAleloMobilidade) ? true
              : (postoItem.goodcard === true && filterByGoodCard))
              : true)
            ).length === 0 && !postoListLoading
              ? "flex"
              : "none",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          margin: "15px",
          height: "60vh",
        }}
      >
        <Box
          sx={{
            width: { md: "100%", lg: "25%" },
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img src={ImgNoPermissionGps} width={"80%"} />
        </Box>
        <Typography
          sx={{
            textAlign: "center",
            fontSize: "15px",
            textTransform: "uppercase",
            lineHeight: "1.4",
            color: "#3d5afe",
          }}
        >
          Aumente o raio e selecione a cidade
          <br />
          de acordo com a sua localização
        </Typography>
      </Box>
      <Box
        sx={{
          visibility: postoListLoading ? "visible" : "hidden",
          display: "flex",
          flex: 1,
          alignItems: "center",
          justifyContent: "center",
          pb: "10px",
        }}
      >
        <CircularProgress size={"20px"} />
      </Box>
      <Box
        sx={{
          display: list.filter(
            (postoItem) =>
              postoItem.rota.distance.value <= Number(filter.distancia) * 1000
          ).length
            ? "flex"
            : "none",
          flexWrap: "wrap",
          padding: "20px;",
          paddingTop: "0px",
          flex: 1,
          marginBottom: "60px",
        }}
      >
        <ins
          className="adsbygoogle"
          style={{ display: 'block' }}
          data-ad-client="ca-pub-4879908324566992"
          data-ad-slot="7721593210"
          data-ad-format="rectangle, horizontal"
          data-full-width-responsive="true"
        ></ins>
        {listSorted.map((postoItem, indexItem) => (
            <ItemPosto
              key={postoItem._id}
              destinyDetails={destinyDetails}
              dados={postoItem}
              lat={location.lat}
              lng={location.lng}
              indexItem={indexItem}
              lastItem={listSorted.filter(item => item[filter.combustivel] > 0)[listSorted.filter(item => item[filter.combustivel] > 0).length - 1]}
              currentCombustivel={filter.combustivel}
            />
          ))}
      </Box>
    </Box>
  );
};

export default PostosList;
